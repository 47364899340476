#roadMap{
    height: fit-content;
    width: 100%;
    margin: auto;
    padding: 10vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 100%;
        max-width: 1000px;
    }
}