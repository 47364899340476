#ourStory{
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    min-height: 100vh;
    overflow: hidden;
    padding: 25vh 0;
    width: 100%;
    margin-top: -10px;
    background-image: linear-gradient(#FFE2E6, #FFFFFF);
    .storyContent{
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        .left{
            // width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 2.5rem;
            img{
                // width: 60%;
                max-height: 550px;
                min-height: 400px;
            }
        }
        .right{
            width: 70%;
            .contentBox{
                margin-left: 2.5rem;
                position: relative;
                
                width: 500px;
                h1{
                    margin-top: 0;
                    font-size: 4rem;
                    margin-bottom: 1rem;
                }
                p{
                    font-size: 2rem;
                    margin-top: 2rem;
                    margin-bottom: 0;
                    line-height: 1.6;
                }
                img{
                    position: absolute;
                    transform: translate(65%, -60%);
                    width: 400px;
    
                }
            }
        }
    }
}