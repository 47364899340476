.Homecontact{
    border: 2px solid black;
    border-radius: 3rem;
    padding: 1rem 3rem;
    transition: .3s;
    font-size: 2rem;
    font-weight: bold;
    &:hover{
        background-color: #D8336D;
        color: white;
        border: 2px solid #D8336D;
        transform: scale(1.1);
    }
}

.Navbar__Link{
    cursor: pointer;
    transition: .3s;
    &:hover{
        color: white;
    }
}