#impactPage{
    padding: 20px;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .body{
        max-width: 1200px;
        width: 100%;
        span{
            font-size: 2rem;
        }
        h1{
            font-size: 4.8rem;
            margin-bottom: 20px;
            text-align: center;
        }
        .subheadLine{
            text-align: center;
            font-size: 2.4rem;
            width: 700px;
            margin: auto;
            margin-bottom: 2rem;
        }
        .impactCards{
            margin: 3rem 0;
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(4,1fr);
            max-width: 1200px;
            width: 100%;
            .impactCard{
                background-color: white;
                box-shadow:  0 0 10px rgba(0,0,0,.1);
                padding: 2rem;
                height: 100%;
                margin: 0 !important;
                border-radius: 10px;
                h1{
                    font-size: 6.4rem;
                    text-align: left;
                    margin-bottom: 10px;
                    margin-top: 0;
                    color: #D8336D;
                }
                p{
                    text-align: left;
                    font-size: 1.8rem;
                    margin-bottom: 10px;
                }
            }
        }
    }
}