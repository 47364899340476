#residentSay{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10vh 2rem;
    .body{
        width: 100%;
        max-width: 1200px;
        display: flex;
        align-items: center;
        .left{
            img{
                width: 100%;
                max-width: 550px;
            }
        }
        .right{
            width: 100%;
            max-width: 700px;
            margin-left: 50px;
            h1{
                font-size: 4rem;
            }
            .messageCard{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 2.5rem;
                .card{
                    border: 1px solid #ECECEC;
                    border-radius: 1.5rem;
                    display: flex;
                    padding: 1.5rem;
                    .avatar{
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        img{
                            width: 40px;
                            height: 40px;
                            margin-right: 1rem;
                            border-radius: 50%;
                        }
                    }
                    .message{
                        .top{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .name{
                                font-size: 1.6rem;
                                font-weight: bold;
                                p{
                                    margin: 0;
                                }
                            }
                            .location{
                                font-size: 1rem;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background-color: #FFF2F7;
                                padding: .3rem .5rem;
                                border-radius: .3rem;
                                img{
                                    margin-right: .5rem;
                                    margin-bottom: 2px;
                                    width: 10px;
                                }
                                p{
                                    color: #D8336D;
                                    margin: 0;
                                }
                            }
                        }
                        .bottom{
                            p{
                                margin: .5rem 0 0 0;
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }
}