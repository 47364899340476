/* General Styles */
*,
*::before,
*::after {
    box-sizing: border-box;
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
    font-style: normal;
}

html, body {
    font-size: 10px; /* Default font size for larger screens */
}

/* Media Query for devices with max-width 1200px */
@media (max-width: 1050px) {
    html, body {
        font-size: 8px; /* Adjusted font size for screens 1200px and below */
    }
    #Navbar{
        #navLinks{
            display: none !important;
        }
    }
    #home{
        #headLine {
            top: 35% !important; /* Adjusted top position */
            h1{
                font-size: 3.5rem !important;
                margin-bottom: 1rem !important;;
            }
    
            p{
                font-size: 3rem !important;;
            }
        }
    }
    #ourStory{
        .storyContent{
            display: flex;
            flex-direction: column;
            .left{
                justify-content: center !important;
                // width: 100% !important;
                img{
                    margin: 0 !important;
                }
            }
            .right{
                width: 100% !important;
                margin-top: 10vh;
                .contentBox{
                    width: 90% !important;
                    margin: 0 5% !important;
                    img{
                        position: absolute !important;
                        top: 0 !important;
                        right: 0 !important;
                        width: 300px !important;
                        transform: translateY(-100%) !important;
                    }
                }
            }
        }
    }
    #missionPage{
        padding: 10vh 2rem !important;
        .body{
            .missionCards{
                flex-direction: column;
                div{
                    width: 100%;
                }
                .card{
                    display: flex;
                    width: 100% !important;
                    height: fit-content !important;
                    flex-direction: row !important;
                    align-items: center !important;
                    margin: 2rem 0 !important;
                    h1{
                        font-size: 2.5rem !important;
                    }
                    p{
                        font-size: 1.7rem !important;
                    }
                    img{
                        margin: 3rem !important;
                        height: 100% !important;
                        width: 70px !important;
                        
                    }
                }
            }
        }
    }
    #impactPage{
        .body{
            .subheadLine{
                width: 100% !important;
            }
            .impactCards{
                display: grid;
                grid-template-columns: repeat(2,1fr) !important;
                .impactCard{
                    margin: 1rem !important;
                    h1{
                        font-size: 5rem !important;
                    }
                }
            }
        }
    }
    #residentSay{
        .body{
            flex-direction: column !important;
            .right{
                margin: unset !important;
                h1{
                    font-size: 3rem !important;
                    text-align: center !important;
                    margin-bottom: 4rem !important;
                }
                .messageCard{
                    grid-template-columns: repeat(1,1fr) !important;
                    .card{
                        .message{
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
    #miniProgram{
        .body{
            .top{
                h1{
                    font-size: 3.5rem !important;
                    margin-bottom: 2rem !important;
                }
                p{
                    font-size: 2rem !important;
                }
            }
        }
    }
    #SSCoin{
        .body{
            width: 90% !important;
            margin: auto !important;
            .coinImage{
                width: 100% !important;
                margin: auto;
                flex-direction: column !important;
                p{
                    font-size: 3rem !important;
                    text-align: center !important;
                }
            }
            #cards{
                grid-template-columns: repeat(1,1fr) !important;
                .card{
                    img{
                        height: 20rem !important;
                    }
                    h1{
                        font-size: 2.8rem !important;
                    }
                    p{
                        font-size: 2.2rem !important;
                    }
                }
            }
            #coinusage {
                grid-template-columns: repeat(4,1fr) !important;
                .coin{
                    margin: 1rem 0 1rem 0 !important;
                    img{
                        width: 15rem !important;
                    }
                    h1{
                        font-size: 1.9rem !important;
                    }
                    &:nth-child(4){
                        transform: unset !important;
                        grid-column: span 2 !important;
                    }
                    &:nth-child(5){
                        transform: unset !important;
                        grid-column: span 4 !important;
                    }
                }
            }
        }
    }
    #roadMap{
        max-width: 400px !important;
    }
    #footer{
        .body{
            flex-direction: column !important;
            align-items: flex-start !important;
            .right{
                .mediaLinks{
                    justify-content: space-between !important;
                    a{
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}