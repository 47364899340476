#SSCoin {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 10vh 2rem;
    padding-bottom: 20vh;

    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .coinImage {
            width: 750px;
            display: flex;
            align-items: center;
            margin-top: 2vh;
            margin-bottom: 3vh;
            justify-content: space-between;

            img {
                width: 150px;
            }

            p {
                color: #D8336D;
                font-size: 4rem;
                font-weight: bold;
            }
        }

        h2 {
            text-align: center;
            font-size: 4rem;
            font-weight: bold;
            &.howtousesscoin{
                margin-top: 20vh;
                margin-bottom: 5vh;
            }
        }

        #cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 3rem;

            .card {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                img {
                    height: 24rem;
                }

                h1,
                p {
                    text-align: center;
                }

                h1 {
                    font-size: 2rem;
                    font-weight: bold;
                    margin-bottom: 1rem;
                }

                p {
                    margin-top: 1rem;
                    font-size: 1.8rem;
                }
            }
        }

        #coinusage {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            .coin{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin: 1rem 0 ;
                img{
                    width: 20rem;
                }
                h1{
                    font-size: 2.5rem;
                    text-align: center;
                }
                &:nth-child(1),&:nth-child(2),&:nth-child(3){
                    grid-column: span 2;
                }
                &:nth-child(4),&:nth-child(5){
                    margin-top: 5rem;
                    grid-column: span 3;
                }
                &:nth-child(4){
                    transform: translateX(18%);
                }
                &:nth-child(5){
                    transform: translateX(-18%);
                }
            }
        }
    }
}