#missionPage{
    height: fit-content;
    padding: 10vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .body{
        display: block;
        max-width: 1200px;
        width: 100%;
        h1{
            text-align: center;
            font-size: 4rem;
        }
        .missionCards{
            display: flex;
            align-items: center;
            justify-content: space-around;
            .card{
                width: 330px;
                height: 330px;
                margin: 0 1rem;
                background-color: white;
                border-radius: 3rem;
                background-image: linear-gradient(#FFE2E6, #FFFFFF);
                padding: 2rem;
                img{
                    height: 60px;
                    margin: 0;
                    margin-top: 1rem;
                }
                h1{
                    text-align: left;
                    font-size: 3.2rem;
                    margin-bottom: 0rem;
                }
                p{
                    text-align: left;
                    margin-top: 1rem;
                    font-size: 2rem;
                    line-height: 1.6;
                }
            }
        }
    }
}