#mobileNav{
    position: fixed;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    height: 100vh;
    .body{
        height: fit-content;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-left: 1.3rem;
        a{
            font-size: 1.5rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &.active{
                font-size: 3rem;
                .circle{
                    height: 5rem;
                    background-color: #D8336D;
                    border: .2rem solid #D8336D;
                }
            }
            .circle{
                height: 1.8rem;
                width: 1.8rem;
                border: .4rem solid grey;
                background-color: transparent;
                margin: .6rem 0;
                border-radius: 2rem;
                transition: .25s;
                cursor: pointer;
                &:hover{
                    background-color: #D8336D;
                    border: .2rem solid #D8336D;
                    transform: scale(1.2);
                }
            }
        }
    }
}