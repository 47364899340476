#home{
    width: 100%;
    overflow: hidden;
    
    img{
        width: 102%;
        height: 100vh;
        padding-top: 80px;
        object-fit: cover;
    }

    #headLine{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: black;
        transition: all 0.5s ease;
        width: 100%;
        h1{
            font-size: 4rem;
            margin-bottom: 1rem;
            font-family: "Shrikhand", serif;
            color: #D8336D;
            font-size: 4.8rem;
            text-shadow: #FFFFFF 5px 5px;
        }

        p{
            font-size: 3.5rem;
        }
    }
}

