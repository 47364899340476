#miniProgram{
    background-color: #FFE4E4;
    height: fit-content;
    padding: 10vh 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .body{
        width: 100%;
        max-width: 1000px;
        margin: auto;
        .top{
            width: 100%;

            h1{
                font-size: 3.6rem;
                text-align: center;
                margin-bottom: 0;
            }
            p{
                margin-top: .5rem;
                font-size: 2rem;
                text-align: center;
            }
        }
        .bottom{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}