#Navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px) saturate(180%);
    font-weight: bold;
    width: 100%;
    font-size: 1.6rem;
    #logo{
        .Navbar__Link.Navbar__Link-brand{
            img{
                width: 160px;
            }
        }
    }
    .Navbar__Link{
        cursor: pointer;
        color: rgb(58, 58, 58);
        transition: .3s;
        &:hover{
            color: #D8336D;
        }
    }
    #navLinks{
        display: flex;
        align-items: center;
        div{
            margin: 0 1.25rem;
        }
        .contact{
            border: 2px solid black;
            border-radius: 3rem;
            padding: .4rem 1.1rem;
            margin-right: 0;
            transition: .3s;
            &:hover{
                background-color: #D8336D;
                color: white;
                border: 2px solid #D8336D;
                transform: scale(1.1);
            }
        }
    }
}


.hover-link {
    position: relative;
    display: inline-block;
    color: #000; /* Text color */
    text-decoration: none; /* Remove default underline */
    padding-bottom: 3px; /* Space for the line */
  }
  
  .hover-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #D8336D; /* Line color */
    transition: width 0.3s ease-in-out; /* Animation timing */
  }
  
  .hover-link:hover::after {
    width: 100%;
  }
  