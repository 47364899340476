#footer{
    background-color: #FFE4E4;
    // color: #fff;
    text-align: center;
    padding: 10px;
    bottom: 0;
    width: 100%;
    z-index: 100;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 5rem;
    width: 100%;
    .body{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        .left{
            width: 380px;
            text-align: left;
            h1{
                font-size: 3.6rem;
            }
            p,b{
                font-size: 1.8rem;
            }
        }
        .right{
            h1{
                font-size: 2rem;
            }
            .mediaLinks{
                display: flex;
                align-items: center;
                justify-content: space-around;
                a{
                    margin: 0 1rem;
                    cursor: pointer;
                    img{
                        width: 4rem;
                    }
                }
            }
        }
    }
    a{
        color: #fff;
        text-decoration: none;
    }
}